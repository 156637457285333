
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width' }, [this.facets(function () {
            function repeatFacets1(facets, facetsIndex) {
                return [facets(function () {
                        function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'aria-label': this.value,
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey chbx',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues2(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'aria-label': this.value,
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey chbx',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues3(Values, ValuesIndex) {
                            return [Values(function () {
                                    function onChange1() {
                                        {
                                        }
                                    }
                                    return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                        'type': 'checkbox',
                                        'checked': this.isSelected,
                                        'className': 'cm_facet_checkbox',
                                        'aria-label': this.value,
                                        'onChange': onChange1.bind(this)
                                    }), !this.isCustomRange ? [
                                        _createElement('a', {
                                            'className': 'facetentrykey chbx',
                                            'tabIndex': '0',
                                            'role': 'listitem',
                                            'key': '1921'
                                        }, _createElement('span', {}, this.value)),
                                        _createElement('div', {
                                            'className': 'facetentryval',
                                            'key': '1923'
                                        }, this.hitCount)
                                    ] : null, this.isCustomRange ? [_createElement('a', {
                                            'className': 'facetentrykey chbx',
                                            'tabIndex': '0',
                                            'role': 'listitem',
                                            'key': '4811'
                                        }, this.finite ? _createElement('span', { 'key': '615' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '700' }, '> ', this.range[0]) : null, this.toNegInf ? _createElement('span', { 'key': '774' }, '< ', this.range[1]) : null)] : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'facetholder' }, _createElement('h6', {
                            'className': 'cm_flex-center facettitle',
                            'data-cm-role': 'toggle-facet',
                            'tabIndex': '0'
                        }, _createElement('div', { 'className': 'cm_flex-grow' }, this.name), this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-angle left',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '39 30 565 565',
                                'aria-hidden': 'true',
                                'key': '1820'
                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-angle down',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '39 30 565 565',
                                'aria-hidden': 'true',
                                'key': '5030'
                            }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, this.template === 'simpleFacet' ? _createElement('div', {
                            'className': 'cmTemplate_simpleFacet',
                            'key': '884'
                        }, this.showFilterInput ? _createElement('div', {
                            'className': 'filter-input',
                            'key': '981'
                        }, [this.filterInput(function () {
                                return _createElement('div', {
                                    'className': 'input FACET_FILTER_CLASS cm_filterInput',
                                    'placeholder': 'Enter'
                                });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], this.inputNotEmpty ? _createElement('span', {
                            'className': 'filter-input_clear-container',
                            'onClick': this.clearInput,
                            'key': '1339'
                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                'className': 'facetdiv',
                                'key': '15481'
                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                        ]), _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_Values' },
                            _map(this.Values, repeatValues2.bind(this))
                        ]), this.needShowMore ? _createElement('div', {
                            'className': 'facetdiv cm_show-all-container',
                            'key': '3501'
                        }, _createElement('div', { 'className': 'facetdiv' }, _createElement('a', {
                            'className': 'cm_ShowAll',
                            'data-cm-role': 'toggle-show-more'
                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null))) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                            'className': 'cmTemplate_priceFacet',
                            'key': '3949'
                        }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_Values' },
                            _map(this.Values, repeatValues3.bind(this))
                        ]), [this.Inputs(function () {
                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Min price',
                                            'aria-label': 'Min price',
                                            'className': 'cm_inputMin'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                        return _createElement('div', {
                                            'placeholder': 'Max price',
                                            'aria-label': 'Max price',
                                            'className': 'cm_inputMax'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('button', {
                                    'className': 'btn cm_btn',
                                    'onClick': this.setCustomRange
                                }, 'GO'));
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]) : null));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_facets' },
                _map(this.facets, repeatFacets1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets"]