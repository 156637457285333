const pageTypeToFieldMap = {
  category: 'Category',
  brand: 'ManufacturerID',
};
function getLocalPreselection(pageType, defaults) {
  const field = pageTypeToFieldMap[pageType];
  if (field) {
    const term = pageType === 'category' ? defaults.termFromBreadcrumbs : `${window.cmCurrentManId}`;
    if (term) {
      return [{ field, term }];
    }
  }
}

const ajustItemsHeight = () => {
  const children = [...document.querySelector('.cm_rightPanel .cmRepeater_items').children];
  const maxHeight = children.reduce((max, child) => Math.max(child.scrollHeight, max), 0);
  children.forEach((item) => (item.style.height = `${maxHeight}px`));
};

const HideResults = () => {
  const request = window.Convermax.getSearchRequest();
  window
    .$('section#category, section#searchGroup')
    [
      request.selection.length > 0 || request.query.length > 0 ? 'addClass' : 'removeClass'
    ]('cm_hideDefaultResults');
};

const addToCartAjax = async (catalogId, quantity, event) => {
  event?.preventDefault();

  const formData = new FormData();

  formData.append('item_id', catalogId);
  formData.append('qty-0', quantity);

  try {
    return await fetch('/frontapi.asp?module=addcartajax&ajaxadd=1', {
      method: 'POST',
      body: formData,
    });
  } catch (error) {
    console.error(`Failed to add to cart. Catalog id: ${catalogId}:`, error);
  }
};

globalThis.Convermax.handleProductSelectClick = () => {
  const button = window.document.body.querySelector('.cm_frequently-bought-together_button');
  if (!button) {
    return;
  }

  const selectedProducts = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.id,
  );

  const buttonText = selectedProducts.length ? 'Add to cart' : 'Add all to cart';
  button.textContent = buttonText;
};

globalThis.Convermax.addSelectedProductsToCart = async (event) => {
  event.preventDefault();

  const selectedProducts = [
    ...window.document.querySelectorAll('.cm_product-checkbox input[name="products[]"]:checked'),
  ].map((product) => product.dataset.id);

  const productsToAdd = selectedProducts.length
    ? selectedProducts
    : [...window.document.querySelectorAll('input[name="products[]"]')].map((product) => product.dataset.id);

  const button = event.target;
  const buttonTextInit = button.textContent;

  button.textContent = 'Adding to cart...';
  event.target.setAttribute('disabled', true);

  try {
    await Promise.allSettled(productsToAdd.map((productId) => addToCartAjax(productId, 1)));
    window.launch_qcart?.();
  } finally {
    button.textContent = buttonTextInit;
    event.target.removeAttribute('disabled');
  }
};

const searchResultsUpdateCallback = () => {
  window.closeParentModal();
  HideResults();
  ajustItemsHeight();
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 200,
  },
  disableRequestScroll: true,
  getLocalPreselection,
  Widgets: [
    {
      name: 'SearchPage',
      location: '#extrapage',
      visibleIf: {
        selector: '#cm_results',
      },
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name: A to Z',
        'Name:desc': 'Name: Z to A',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields: ['Brand'],
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { lastChildOf: '#cm_categoryChipsNFacets' },
      visibleIf: () => !document.body.classList.contains('cm_disable-filters'),
      template: 'FacetPanelContainer',
      ignoreFields: ['Brand'],
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_categorySearchPage',
      visibleIf: () => !document.body.classList.contains('cm_disable-filters'),
      template: 'CategoryPage',
    },
    {
      name: 'SearchResult',
      updateCallback: searchResultsUpdateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'FacetPanelTilesContainer',
      type: 'SearchPage',
      location: '#cm_facetPanelTiles__container',
      template: 'FacetPanelTilesContainer',
      visibleIf: () => !document.body.classList.contains('cm_disable-filters'),
    },
    {
      name: 'FacetPanelTiles',
      type: 'FacetPanel',
      disableCollapse: true,
      showAllAlways: true,
      fields: ['Brand'],
    },
    {
      name: 'FrequentlyBoughtTogether',
      type: 'RelatedItems',
      location: { insertBefore: '.relatedBlock' },
      template: 'product/frequentlyBoughtTogether',
      count: 5,
      useManualItems: true,
      visibleIf: () => window.document.querySelector('input#catalogid[name="item_id"]'),
    },
  ],
};
