
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_flex clearfix container' }, _createElement('div', { 'className': 'cm_leftPanel cm_chipsNFacets' }, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_Facets cm_FacetPanel' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'aria-label': this.value,
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey chbx',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'aria-label': this.value,
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey chbx',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function onChange1() {
                                                {
                                                }
                                            }
                                            return _createElement('div', { 'className': 'facetdiv' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'checked': this.isSelected,
                                                'className': 'cm_facet_checkbox',
                                                'aria-label': this.value,
                                                'onChange': onChange1.bind(this)
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey chbx',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1921'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1923'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey chbx',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '4811'
                                                }, this.finite ? _createElement('span', { 'key': '615' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '700' }, '> ', this.range[0]) : null, this.toNegInf ? _createElement('span', { 'key': '774' }, '< ', this.range[1]) : null)] : null);
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder' }, _createElement('h6', {
                                    'className': 'cm_flex-center facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, this.name), this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-angle left',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '39 30 565 565',
                                        'aria-hidden': 'true',
                                        'key': '1820'
                                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-angle down',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '39 30 565 565',
                                        'aria-hidden': 'true',
                                        'key': '5030'
                                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                    'className': 'facetbody',
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '884'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '981'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input FACET_FILTER_CLASS cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1339'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '15481'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3501'
                                }, _createElement('div', { 'className': 'facetdiv' }, _createElement('a', {
                                    'className': 'cm_ShowAll',
                                    'data-cm-role': 'toggle-show-more'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null))) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '3949'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues3.bind(this))
                                ]), [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'aria-label': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'aria-label': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'className': 'btn cm_btn',
                                            'onClick': this.setCustomRange
                                        }, 'GO'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('img', {
        'src': 'https://www.stage3motorsports.com/assets/images/Design%20Stuff/Why%20Buy%20From%20Us%20Banner.jpg',
        'alt': 'Why buy from us?'
    })), _createElement('div', { 'className': 'cm_rightPanel' }, _createElement('div', { 'id': 'itemsBlock' }, _createElement('div', { 'siq_id': 'autopick_9591' }, [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('h2', { 'className': 'titles' }, '[Products ', this.totalHits, ']')), [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'btn btn-default btn-inverse btn btn-default cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '890'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8300'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], this.totalHits > 0 ? _createElement('div', {
                'className': 'cm_sort sort-by',
                'key': '2295'
            }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n    ', this.sortSelect, '\n  ') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('span', { 'className': 'cmTemplate_prev' }, _createElement('a', { 'href': this.pageLink }, 'Previous Page')) : this.template === 'next' ? _createElement('span', { 'className': 'cmTemplate_next' }, _createElement('a', { 'href': this.pageLink }, 'Next Page')) : this.template === 'delimiter' ? _createElement('span', { 'className': 'cmTemplate_delimiter' }, _createElement('span', {}, '...')) : this.template === 'page' ? _createElement('span', { 'className': 'cmTemplate_page' }, this.isActive ? _createElement('strong', { 'key': '35' }, this.page) : null, !this.isActive ? _createElement('a', {
                            'href': this.pageLink,
                            'className': 'paging-links',
                            'key': '90'
                        }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'paging cm_pagination' }, _createElement('span', { 'className': 'cm_mobile-hide' }, 'More results: '), _createElement.apply(this, [
                'span',
                { 'className': 'cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'pagination-top',
            items: undefined
        })], _createElement('div', { 'className': 'clear' }), [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function onClick1() {
                            window.Convermax.handleProductSelectClick();
                        }
                        return _createElement('div', {
                            'className': 'product-item',
                            'data-catalogid': this.CatalogID,
                            'data-ajaxcart': '1',
                            'data-addcart-callback': 'addcart_callback'
                        }, _createElement('div', { 'className': 'product-item-container' }, _createElement('div', { 'className': 'img' }, _createElement('a', { 'href': this.ProductLink }, _createElement('img', {
                            'src': this.imageOrDefault(this.ThumbnailFile),
                            'alt': this.removeHTML(this.Name),
                            'className': 'img-responsive',
                            'onError': this.onImageError
                        })), window.isQuickviewEnabled() ? _createElement('button', {
                            'className': 'quickview',
                            'data-toggle': 'modal',
                            'key': '385'
                        }, 'Quick View') : null), _createElement('div', { 'className': 'reviews' }, _createElement('span', {
                            'className': 'reviews-stars rating-' + this.ReviewAverage,
                            'role': 'presentation'
                        }), this.HasVideo ? _createElement('img', {
                            'className': 'vidtag',
                            'src': '/assets/images/Design Stuff/Video Tag Grey.png',
                            'alt': 'Video',
                            'key': '614'
                        }) : null), _createElement('div', { 'className': 'name' }, _createElement('a', Object.assign({}, { 'href': this.ProductLink }, { dangerouslySetInnerHTML: { __html: this.Name } }))), _createElement('div', { 'className': 'price' }, _createElement('span', { 'className': this.OnSale ? 'sale-price' : 'regular-price' }, this.PriceStr), this.OnSale ? _createElement('span', {
                            'className': 'sale-price',
                            'key': '1004'
                        }, _createElement('img', {
                            'src': 'https://www.stage3motorsports.com/assets/images/Design%20Stuff/Sale-Icon.png',
                            'alt': 'On Sale Now!'
                        })) : null), _createElement('label', {
                            'className': 'cm_product-checkbox',
                            'htmlFor': 'select-' + this.CatalogID,
                            'style': { display: 'none' }
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'id': 'select-' + this.CatalogID,
                            'data-id': this.CatalogID,
                            'onClick': onClick1.bind(this)
                        }), '\n      SELECT\n    ')));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'product-items product-items-4 cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'clear' }), [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('span', { 'className': 'cmTemplate_prev' }, _createElement('a', { 'href': this.pageLink }, 'Previous Page')) : this.template === 'next' ? _createElement('span', { 'className': 'cmTemplate_next' }, _createElement('a', { 'href': this.pageLink }, 'Next Page')) : this.template === 'delimiter' ? _createElement('span', { 'className': 'cmTemplate_delimiter' }, _createElement('span', {}, '...')) : this.template === 'page' ? _createElement('span', { 'className': 'cmTemplate_page' }, this.isActive ? _createElement('strong', { 'key': '35' }, this.page) : null, !this.isActive ? _createElement('a', {
                            'href': this.pageLink,
                            'className': 'paging-links',
                            'key': '90'
                        }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'paging cm_pagination' }, _createElement('span', { 'className': 'cm_mobile-hide' }, 'More results: '), _createElement.apply(this, [
                'span',
                { 'className': 'cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'pagination-bottom',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:pagination","cm:SearchResult","cm:pagination"]