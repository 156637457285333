
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function frequentlyBoughtTogetherRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function onClick1() {
                    window.Convermax.handleProductSelectClick();
                }
                return _createElement('div', {
                    'className': 'product-item',
                    'data-catalogid': this.CatalogID,
                    'data-ajaxcart': '1',
                    'data-addcart-callback': 'addcart_callback'
                }, _createElement('div', { 'className': 'product-item-container' }, _createElement('div', { 'className': 'img' }, _createElement('a', { 'href': this.ProductLink }, _createElement('img', {
                    'src': this.imageOrDefault(this.ThumbnailFile),
                    'alt': this.removeHTML(this.Name),
                    'className': 'img-responsive',
                    'onError': this.onImageError
                })), window.isQuickviewEnabled() ? _createElement('button', {
                    'className': 'quickview',
                    'data-toggle': 'modal',
                    'key': '385'
                }, 'Quick View') : null), _createElement('div', { 'className': 'reviews' }, _createElement('span', {
                    'className': 'reviews-stars rating-' + this.ReviewAverage,
                    'role': 'presentation'
                }), this.HasVideo ? _createElement('img', {
                    'className': 'vidtag',
                    'src': '/assets/images/Design Stuff/Video Tag Grey.png',
                    'alt': 'Video',
                    'key': '614'
                }) : null), _createElement('div', { 'className': 'name' }, _createElement('a', Object.assign({}, { 'href': this.ProductLink }, { dangerouslySetInnerHTML: { __html: this.Name } }))), _createElement('div', { 'className': 'price' }, _createElement('span', { 'className': this.OnSale ? 'sale-price' : 'regular-price' }, this.PriceStr), this.OnSale ? _createElement('span', {
                    'className': 'sale-price',
                    'key': '1004'
                }, _createElement('img', {
                    'src': 'https://www.stage3motorsports.com/assets/images/Design%20Stuff/Sale-Icon.png',
                    'alt': 'On Sale Now!'
                })) : null), _createElement('label', {
                    'className': 'cm_product-checkbox',
                    'htmlFor': 'select-' + this.CatalogID,
                    'style': { display: 'none' }
                }, _createElement('input', {
                    'type': 'checkbox',
                    'name': 'products[]',
                    'id': 'select-' + this.CatalogID,
                    'data-id': this.CatalogID,
                    'onClick': onClick1.bind(this)
                }), '\n      SELECT\n    ')));
            }, { count: undefined })];
    }
    function onClick2(e) {
        window.Convermax.addSelectedProductsToCart(e);
    }
    return _createElement('div', { 'className': 'cm_related-products cm_frequently-bought-together container' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'header text-center cm_related-products-title' }, 'Frequently Bought Together')), _createElement.apply(this, [
        'div',
        { 'className': 'product-items product-items-5 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]), _createElement('div', { 'className': 'cm_frequently-bought-together_button-container' }, _createElement('button', {
        'className': 'cm_frequently-bought-together_button btn btn-default',
        'onClick': onClick2.bind(this)
    }, '\n      Add all to cart\n    ')));
}
        export const componentNames = []