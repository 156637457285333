export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-default',

  FACET_DIALOG_TITLE: 'Filter Options',
  FACET_DIALOG_BUTTON_CLASS: 'btn btn-default btn-inverse',
  SHOW_RESULTS_CLASS: 'btn btn-default',

  SEARCH_BOX_PLACEHOLDER: 'search',
  SEARCH_BOX_FORM_CLASS: 'search-form',
  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'search-submit btn btn-default',

  RELATED_PRODUCT_CONTAINER_CLASS: 'container',
  RELATED_PRODUCT_TITLE_CLASS: 'header text-center',
  RELATED_PRODUCT_LIST_CLASS: 'product-items product-items-5',
};
